export default interface ProfileModel {
    pristine: boolean
    sex: 'male' | 'female'
    age: number 
    height: number
    weight: number
    skipBreakfast: boolean
}

export function userDailyEnergyNeeds(profile: ProfileModel): number {
    const baseMetabolism = (profile.sex === 'male' ? 1.083 : 0.963) * Math.pow(profile.weight, 0.48) * Math.pow(profile.height, 0.5) * Math.pow(profile.age, -0.13) * 1000 / 4.1855;
    return Math.trunc(baseMetabolism * 1.5)
}