import './styles/_variables.sass'
import './styles/main.sass'

import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ROUTES } from './resources/routes-constants'
// import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import RestaurantPage from './pages/RestaurantPage'
import ProfilePage from './pages/ProfilePage'

function RootComponent() {
    return (
        <Router>
            <Routes>
                <Route path='*' element={<NotFoundPage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<NotFoundPage />} />
                <Route path={ROUTES.RESTAURANT_ROUTE} element={<RestaurantPage />}/>
                <Route path={ROUTES.PROFILE_ROUTE} element={<ProfilePage />}/>
            </Routes>
        </Router>
    )
}

export default RootComponent
