import { range } from '../utility/functions'

export const mealLevels = {
  light: {label: 'léger', coeff: 0.75},
  normal: {label: 'normal', coeff: 1},
  pleasure: {label: 'plaisir', coeff: 1.25}, 
  at_will: {label: 'à volonté', coeff: -1}, 
}

export const mealTypes = { 
  breakfast: {label: 'petit-déjeuner', ratio: 0.25}, 
  lunch: ({label: 'déjeuner', ratio: 0.4}), 
  dinner: ({label: 'dîner', ratio: 0.35}),
}

export const sexOptions = [
    {value: 'female', label: 'je suis une femme' },
    {value: 'male', label: 'je suis un homme' },
]

export const ageOptions = range(18, 80).reverse().map((age) => ({ value: age, label: `j'ai ${age} ans` }))

export const heightOptions = range(1.20, 2.20, 0.01).reverse().map((height) => ({ value: height, label: 'je mesure ' + convertToCustomString(height)}))

export const weightOptions = range(40, 160).reverse().map((weight) => ({ value: weight, label: `je pèse ${weight.toString()} kg` }))

export const skipBreakfastOptions = [
  {value: false, label: 'je prends un petit déjeuner' },
  {value: true, label: 'je ne prends pas de petit déjeuner' }
]

function convertToCustomString(number: number): string {
    const parts = number.toString().split('.');
    if (parts.length === 1) {
      return parts[0] + 'm00';
    } else {
      const decimalPart = parts[1].padEnd(2, '0').substring(0, 2);
      return parts[0] + 'm' + decimalPart;
    }
  }

  export const weekdayNames = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
  export const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']