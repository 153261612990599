import React, { useState, useEffect } from 'react'
import CardModel from '../model/CardModel'
import Markdown from 'react-markdown'
import '../styles/Card.sass'

interface CardProps {
  card: CardModel
  collapseSpotlight: () => void
}

function Card( {card, collapseSpotlight}: CardProps ) {

  return (
    <>
      <div id='close-button' onClick={() => collapseSpotlight()}>
        <img className='close-icon' alt='icône croix' src='/assets/close_icon.svg' />
      </div>
      <div id='spotlight-expanded-container'>
        <div className='card-cover' style={{backgroundImage: "url(" + card.cover_url + ")"}}>
        </div>
        <div className='card-container'>
          <div className='card-title-and-content'>
            <div className='card-title'>
              {card.title}
            </div>
            <div className='card-content'>
              <Markdown>{card.content}</Markdown>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
