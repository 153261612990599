import React from 'react'

import '../styles/Container.sass'
import BarSectionContainerModel from '../model/BarSectionContainerModel'
import { useAppDispatch, useAppSelector } from '../store/hook'
import { setContainer, notSelectableContainer } from '../store/reducers/meal'
import { IContainer, ISection } from '@myfoodlifesas/db/lib/main/models/menu'

function Container({ container, section}: { container: IContainer, section: ISection}) {
    const { menu } = useAppSelector((state) => state.menu)
    const meal = useAppSelector(state => state.meal)
    const profile = useAppSelector(state => state.profile)
    const dispatch = useAppDispatch()
    const barSectionContainer: BarSectionContainerModel = {
        section_name: section.name,
        container_name: container.name,
      }

    if (menu != undefined) {
        const isSelected = meal.barSectionContainers.filter((bsc) => bsc.section_name === section.name).map((bsc) => bsc.container_name).includes(container.name)
        const notSelectable = notSelectableContainer(container, section, meal, profile, menu)
        return (
            <div className={`bar-section-container ${isSelected ? 'is-selected' : ''} ${notSelectable ? 'not-selectable' : ''}`}
                key={container.name}
                onClick={ () => notSelectable ? null : dispatch(setContainer({section: section, barSectionContainer: barSectionContainer, profile: profile, menu: menu }))}
                >
                {container.name}
            </div>
        )    
    }
 }
 
 export default Container