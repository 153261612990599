import { createSlice } from '@reduxjs/toolkit'
import { fetchMenu } from '../actions/menu'
import { IMenu } from '@myfoodlifesas/db'

interface MenuReducer {
    loading: boolean
    menu: IMenu | null
}

const initialState: MenuReducer = {
    loading: true,
    menu: null
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMenu.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchMenu.fulfilled, (state, { payload }) => {
            state.loading = false
            state.menu = payload
        })  
        builder.addCase(fetchMenu.rejected, (state) => {
            state.loading = false
        })
    }
})

export default menuSlice.reducer
