import { mealMaxEnergy } from '../model/MealModel'
import { mealLevels } from '../resources/constants'
import { useAppDispatch, useAppSelector } from '../store/hook'
import { setLevel } from '../store/reducers/meal'
import '../styles/MealLevelPicker.sass'

import React from 'react'

function MealLevelPicker() {
  const { menu } = useAppSelector((state) => state.menu)
  const meal = useAppSelector(state => state.meal)
  const profile = useAppSelector(state => state.profile)
  const dispatch = useAppDispatch()
  
  if (menu != undefined) {
    return (
        <div className='meal-level-picker'>
            <div className='meal-level-invite'>
                Je veux déjeuner :
            </div>
            <div className='meal-level-buttons'>
            { (Object.keys(mealLevels) as (keyof typeof mealLevels)[]).map((level) => (
              <div
                  className={`meal-level-button ${meal.level === level ? 'is-selected' : ''}`} 
                  onClick={ () => dispatch(setLevel({meal: meal, level: level, profile: profile, menu: menu}))}
                  key={level}
              >
                  {mealLevels[level].label}
                  { level !== 'at_will' ? (
                      <div className='max-energy'>
                      jusqu&rsquo;à {mealMaxEnergy(meal, profile)} kCal
                      </div>
                  ) : null }
              </div>
            ))}
              </div>
        </div>
    )
}
else {
    return null
  }  
}

export default MealLevelPicker