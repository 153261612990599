import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IMenu } from "@myfoodlifesas/db" 

interface FetchMenuParam {
  restaurantId: string
  searchParams: URLSearchParams
}

export const fetchMenu = createAsyncThunk<IMenu | null, FetchMenuParam>('fetchMenu', async (param) => {
    try {
        const data = await axios.get('/api/restaurants/' + param.restaurantId + '/menu', {timeout: 5000}).then((response) => response.data) as IMenu
        
        const features = {
            display_dish_images: param.searchParams.has('display_dish_images'),
            display_dish_nutriscores: param.searchParams.has('display_dish_nutriscores'),
            display_dish_ecoscores: param.searchParams.has('display_dish_ecoscores'),
            display_dish_carbonscores:  param.searchParams.has('display_dish_carbonscores'),
            display_dish_allergens: param.searchParams.has('display_dish_allergens'),
            display_dish_labels: param.searchParams.has('display_dish_labels'),
            display_dish_tags: param.searchParams.has('display_dish_tags'),
            display_tray_energy: param.searchParams.has('display_tray_energy'),
            display_tray_nutriscore: param.searchParams.has('display_tray_nutriscore'),
            display_tray_ecoscore: param.searchParams.has('display_tray_ecoscore'),
            display_tray_carbonscore: param.searchParams.has('display_tray_carbonscore'),
            display_tray_nutrients: param.searchParams.has('display_tray_nutrients'),
            allow_skip_breakfast: param.searchParams.has('allow_skip_breakfast'),
            spotlightActive: param.searchParams.has('spotlightActive'),
        };
        return {...data, features: features}
      } catch (e) {
        console.error(e)
        return null
    }
})
