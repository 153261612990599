import React, { useState, useEffect } from 'react'
import PollModel from '../model/PollModel'
import { PollOption, PollResponseModel } from '../model/PollResponseModel'
import '../styles/Poll.sass'

interface PollProps {
  poll: PollModel
  pollResponse: PollResponseModel
  handlePollResponse: (response: PollResponseModel) => void
  collapseSpotlight: () => void
}

function Poll( {poll, pollResponse, handlePollResponse, collapseSpotlight}: PollProps ) {
  const [stage, setStage] = useState<'intro' | 'question' | 'thankYou' | 'end' | 'updated' >('intro')

  useEffect(() => {
    if (stage === 'intro') {
      const timer = setTimeout(() => {
        setStage('question')
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [stage])

  useEffect(() => {
    if (stage === 'thankYou') {
      const timer = setTimeout(() => {
        setStage('end')
      }, 1500)

      return () => clearTimeout(timer)
    }
  }, [stage])

  useEffect(() => {
    if (stage === 'updated') {
      setStage('end')
    }
  }, [stage])

  function handleOptionClick(optionClicked: string) {
    if ( poll.poll_type === 'closed-ended-single' || poll.poll_type === 'closed-ended-multiple' ) {
      pollResponse.userSelection = poll.options_list.map((option, index) => ({
        "value": option,
        "isSelected": option === optionClicked ?
          (poll.poll_type === 'closed-ended-multiple' ?
            !(pollResponse.userSelection[index].isSelected)
            : true)
          : (poll.poll_type === 'closed-ended-multiple' ?
              pollResponse.userSelection[index].isSelected
              : false)
      }))
    }
    if ( poll.poll_type === 'nps') {
      pollResponse.userResponse = optionClicked
    }
    handlePollResponse(pollResponse)
    if (stage === 'question') {
      setStage('thankYou')
    }
    else {
      setStage('updated')
    }
      
  }

  return (
    <>
      <div id='close-button' onClick={() => collapseSpotlight()}>
        <img className='close-icon' alt='icône croix' src='/assets/close_icon.svg' />
      </div>
      <div id='spotlight-expanded-container'>

        <div id='scroll-box'>
            <div className='poll-message left'>
              <span>Bonjour ! Aujourd’hui, une question rapide sur&nbsp; <span><b>{poll.about}</b></span>&nbsp;:</span>
            </div>
          { ['question', 'thankYou', 'end', 'updated'].includes(stage) && (
            <>
              <div className='poll-message right'>
                <div className='question-title'>
                    { poll.question_title }
                </div>
                { poll.poll_type === 'closed-ended-single' && (
                  <div className='options-list'>
                    { poll.options_list.map((option, index) =>
                      <div className={`option ${pollResponse.userSelection[index].isSelected ? 'selected' : ''}`} key={"option_" + index.toString()} onClick={() => handleOptionClick(option)}>
                        { option }
                      </div>
                      )
                    }
                  </div>
                  )
                }
                { poll.poll_type === 'closed-ended-multiple' && (
                  <div className='tag-options-list'>
                    { poll.options_list.map((option, index) =>
                      <div className={`option ${pollResponse.userSelection[index].isSelected ? 'selected' : ''}`} key={"option_" + index.toString()} onClick={() => handleOptionClick(option)}>
                        { option }
                      </div>
                      )
                    }
                  </div>
                  )
                }
                { poll.poll_type === 'nps' && (
                  <div className='nps-interface'>
                    <div className='nps-scale-caption'>
                      <div>Absolument pas</div>
                      <div>Totalement</div>
                    </div>
                    <div className='nps-options-list'>
                      {
                        Array.from({ length: 11 }, (_, i) => i).map((i) =>
                          <div className={`nps-option ${'nps-color-' + i} ${pollResponse.userResponse === i.toString() ? 'selected' : ''}`} key={i+1} onClick={() => handleOptionClick(i.toString())}>
                            {  i }
                          </div>
                        )
                      }
                    </div>
                  </div>
                  )
                }
              </div>
            </>
          )
          }
    
          { ['thankYou', 'end', 'updated'].includes(stage) &&
            <div className='poll-message left'>
              <span>Merci pour ce retour. Vous pouvez modifier {poll.poll_type === 'closed-ended-multiple' ? 'ou compléter' : ''} votre réponse si vous le souhaitez. Bonne journée.</span>
            </div>
          }

          { ['end', 'updated'].includes(stage) &&
            <div onClick={() => collapseSpotlight()}>
              <div className='poll-message right'>
                <div className='complete-button'>
                  TERMINER
                </div>
              </div>
            </div>
          }
        </div>

      </div>
    </>
  )
}

export default Poll
