import '../styles/Summary.sass'
import '../styles/MealLevelPicker.sass'
import React from 'react'
import { useAppSelector } from '../store/hook'
import Dish from './Dish'
import { mealLevels } from '../resources/constants'
import { energy, energyFromSection, mealMaxEnergy } from '../model/MealModel'
import { IMenu } from '@myfoodlifesas/db'


function Summary({ menu }: { menu: IMenu }) {

    const meal = useAppSelector(state => state.meal)
    const profile = useAppSelector(state => state.profile)
    const mealDishesIds = meal.dishes.map((dish) => dish.id)
    const summarySections = menu.sections.filter((section) => section.dishes.some((dish) => mealDishesIds.includes(dish.id)))
    
    return (
        <div id='summary'>

            <div id='summary-sections-list'>
                <div className='summary-header'>
                    <div className='summary-title'>
                        Mon récap&nbsp;&nbsp;
                    </div>
                    <div className={`meal-level-button is-selected`}>
                        {'déjeuner ' + mealLevels[meal.level].label}
                        { meal.level !== 'at_will' ? (
                            <div className='max-energy'>
                                jusqu&rsquo;à {mealMaxEnergy(meal, profile)} kCal
                            </div>
                        ) : null }
                    </div>
                </div>
                {summarySections.map((section) => (
                    <div key={section.name} className={`section ${section.bar ? 'bar' : '' }`}>
                        { section.bar ?
                            <>
                                <div className='bar-section-recap'>
                                    <div className='bar-section-name-and-container'>
                                        <div className='section-name'>
                                            {section.name}
                                        </div>
                                        <div className='bar-section-container is-selected'>
                                            {meal.barSectionContainers.find((bsc) => bsc.section_name === section.name) != undefined ?
                                                    meal.barSectionContainers.find((bsc) => bsc.section_name === section.name)?.container_name
                                                : ''
                                            }
                                        </div>
                                    </div>
                                    <div className={`bar-section-energy`}>
                                        {energyFromSection(meal, menu, section)} kCal
                                    </div>
                                </div>
                            </>
                            : null
                        }
                        
                        <div className='dish-list'>
                            {
                                section.dishes.filter((dish) => mealDishesIds.includes(dish.id)).map((dish) => 
                                    (
                                        <Dish 
                                            key={dish.id}
                                            dish={dish} 
                                            section={section}
                                            menu={menu}
                                            features={menu.restaurant.features} 
                                            context='summary'
                                            />
                                    )
                                )
                            }
                        </div>
                    </div>
                ))}

                <div className='meal-energy-summary'>
                    <div className='meal-energy-label'>
                        Total :
                    </div> 
                    <div className='meal-energy'>
                        {energy(meal, menu)} kCal                   
                    </div> 
                </div>
                <div className='summary-exit'>
                    <div className='summary-greeting'>
                        Bon appétit !
                    </div>
                    <a className='myfoodlife-copyright' href='https://compose.myfoodlife.com'>
                        <span>&copy;&nbsp;</span>
                        <img className='myfoodlife-logo' src='/assets/myfoodlife_logo_white_bordered_14_shadow_420-min.png' alt='myfoodlife'/>
                    </a>                
                </div>
            </div>

        </div>
    )
}

export default Summary