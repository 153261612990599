import { IMenu } from '@myfoodlifesas/db'
import MealModel, { energy, mealMaxEnergy } from '../../model/MealModel'
import ProfileModel from '../../model/ProfileModel'
import { ISection } from '@myfoodlifesas/db/lib/main/models/menu'


export default function updateMealDishes(meal: MealModel, profile: ProfileModel, menu: IMenu, withinSection?: ISection) {

    if (menu != undefined) {
        
        // can be called after different user actions:
        //   - profile update
        //   - meal level update
        //   - container update in a bar section
        
        if (meal.dishes.length === 0 || meal.level === 'at_will') { // no dish to remove or at will meal level
            return
        }
        else {
            const maxEnergy = mealMaxEnergy(meal, profile)
            const mealDishesIds = meal.dishes.map((dish) => dish.id)

            if (withinSection) {
                if (withinSection.bar) {
                    // first if there is no more selected container, all dishes within the section need to be removed, if any
                    if (meal.barSectionContainers != undefined) {
                        if (meal.barSectionContainers.filter((bsc) => bsc.section_name === withinSection.name).length === 0) {
                            withinSection.dishes.forEach((dish) => {
                                const index = meal.dishes.findIndex((d) => dish.id === d.id)
                                if (index !== -1) { 
                                    meal.dishes.splice(index, 1)
                                }
                            })
                        }
                    }


                    // second, if there is no more non-additive dish in the specified section, all additive dishes in that section need to be removed too
                    //   ex: the unique non-additive dish in a bar section has just been remmoved
                    //   (a container with only toppings in a bar section is not considered a valid)
                    if (withinSection.dishes.filter((dish) => dish.non_additive && mealDishesIds.includes(dish.id)).length === 0) {
                        withinSection.dishes.filter((d) => !d.non_additive).forEach((additiveDish) => {
                            const index = meal.dishes.findIndex((dish) => dish.id === additiveDish.id)
                            if (index !== -1) {
                                meal.dishes.splice(index, 1)
                            }
                        })
                    }

                    // third, if for instance a bigger container has been selected and the result won't fit,
                    // start removing the additive (e.g. toppings) first until it fits if possible
                    const selectedAdditiveDishes = withinSection.dishes.filter((dish) => !dish.non_additive && mealDishesIds.includes(dish.id))
                    if (selectedAdditiveDishes.length > 0) {
                        while (energy(meal, menu) > maxEnergy) {
                            const lastSelectedAdditiveDish = selectedAdditiveDishes[selectedAdditiveDishes.length - 1]
                            if (lastSelectedAdditiveDish == undefined) {
                                break
                            }
                            const index = meal.dishes.findIndex((dish) => dish.id === lastSelectedAdditiveDish.id)
                            meal.dishes.splice(index, 1)
                            selectedAdditiveDishes.pop()
                        }
                    }

                    // fourth, some non-additive dishes might need to be removed if user just removed a non-additive dish in a bar sections
                    // example: low calorie non-addititive dish and high-calorie non-additive dish were selected and the average was ok
                    // and user removes the low-calorie one and now it does not fit with the high-calorie one alone...
                    // strategy is not to start removing the most recently added but the highest calorie first
                    const selectedNonAdditiveDishes = withinSection.dishes.filter((dish) => dish.non_additive && mealDishesIds.includes(dish.id)).sort((a,b) => a.energy - b.energy)
                    if (selectedNonAdditiveDishes.length > 0) {
                        while (energy(meal, menu) > maxEnergy) {
                            const lastSelectedNonAdditiveDish = selectedNonAdditiveDishes[selectedNonAdditiveDishes.length - 1]
                            if (lastSelectedNonAdditiveDish == undefined) {
                                break
                            }
                            const index = meal.dishes.findIndex((dish) => dish.id === lastSelectedNonAdditiveDish.id)
                            meal.dishes.splice(index, 1)
                            selectedNonAdditiveDishes.pop()
                        }
                    }
                }
            }

            // now, if this was not enough, remove most recently selected dishes one by one until it fits

            while (energy(meal, menu) > maxEnergy) {
                meal.dishes.pop()
            }
        }
    }
}