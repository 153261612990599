import { createSlice } from '@reduxjs/toolkit'
import BarSectionContainerModel from '../../model/BarSectionContainerModel'
import ProfileModel from '../../model/ProfileModel'
import MealModel, { energy, energyFromSection, mealMaxEnergy } from '../../model/MealModel'
import { mealLevels } from '../../resources/constants'
import updateMealDishes from '../actions/meal'
import { IMenu } from '@myfoodlifesas/db'
import { IContainer, IDish, ISection } from '@myfoodlifesas/db/lib/main/models/menu'

const initialState: MealModel = {
    date: '',
    dishes: [],
    barSectionContainers: [],
    type: 'lunch',
    level: 'normal',
}


export function notSelectable(dish: IDish, section: ISection, meal: MealModel, profile: ProfileModel, menu: IMenu): boolean {
    if (!section.bar) { // if not in a bar section, not selectable if remaining energy is not sufficient
        return (meal.level !== 'at_will') && (energy(meal, menu, dish) > mealMaxEnergy(meal, profile))    
    }
    else { // if in a bar section
        // not selectable if no container has been selected
        if (meal.barSectionContainers == undefined) {
            return true
        }
        else if (meal.barSectionContainers.filter((bsc) => bsc.section_name === section.name).length === 0) {
            return true
        }

        // not selectable if dish is additive and no non-additive dish has been selected
        if (!dish.non_additive && section.dishes.filter(d => d.non_additive && meal.dishes.map((dish) => dish.id).includes(d.id)).length === 0) {
            return true
        }
        else { // not selectable if selecting it would bring meal energy beyong meal max energy
            return (meal.level !== 'at_will') && (energy(meal, menu, dish) > mealMaxEnergy(meal, profile))    
        }
    }
}

export function notSelectableContainer(container: IContainer, section: ISection, meal: MealModel, profile: ProfileModel, menu: IMenu): boolean {
    if (!section.bar) { // if not in a bar section (should never happen)
        return true
    }
    else { // if in a bar section
        // not selectable if there is no non-additive dish in the section
        if (section.dishes.filter((dish) => dish.non_additive).length === 0) {
            return true
        }

        // not selectable if level not at will and meal energy without the energy from the section allows at least to pick the lowest energy non-additive dish alone
        const lowestEnergy = container.weight / 100 * section.dishes.filter((dish) => dish.non_additive).sort((a,b) => a.energy - b.energy)[0].energy
        if ( (meal.level !== 'at_will') && energy(meal, menu) - energyFromSection(meal, menu, section) + lowestEnergy > mealMaxEnergy(meal, profile)) {
            return true

        }
        
        return false
    }
}

export const mealSlice = createSlice({
    name: 'meal',
    initialState,
    reducers: {
        toggleDish(state, action: { payload: {dish: IDish, section: ISection, meal: MealModel, profile: ProfileModel, menu: IMenu }}) {
            const index = state.dishes.findIndex((dish) => dish.id === action.payload.dish.id)
            if (index !== -1) { // the dish is selected
                state.dishes.splice(index, 1)   // first remove it
                                                // then, updateMealDishes (because removing a selected dish in a bar section
                                                // can imply to remove other dishes previously selected in that section
                                                // according to the general strategy)
                updateMealDishes(state, action.payload.profile, action.payload.menu, action.payload.section)
            }
            else {
                state.dishes.push(action.payload.dish)
            }
        },
        clearMealDishes(meal: MealModel) {
            meal.dishes = []
            if (meal.barSectionContainers) {
                meal.barSectionContainers = []
            }
        },        
        setLevel(state, action: { payload: {level: keyof typeof mealLevels, profile: ProfileModel, menu: IMenu, meal: MealModel } }) {
            state.level = action.payload.level
            if (state.level !== 'at_will') {
                updateMealDishes(state, action.payload.profile, action.payload.menu)
            }
        },
        checkMenu(menu, action: { payload: IMenu }) {
            const menuDishes = action.payload.sections.flatMap((section) => section.dishes)
            menu.dishes = menu.dishes.filter((dish) => menuDishes.some((menuDish) => menuDish.id === dish.id))
        },
        setContainer(meal, action: { payload: { section: ISection, barSectionContainer: BarSectionContainerModel, profile: ProfileModel, menu: IMenu } }) {
            if (meal.barSectionContainers) {
                const index = meal.barSectionContainers.findIndex((bsc) => bsc.section_name === action.payload.barSectionContainer.section_name && bsc.container_name === action.payload.barSectionContainer.container_name)
                if (index !== -1) {
                    meal.barSectionContainers = meal.barSectionContainers.filter((bsc) => bsc.section_name !== action.payload.barSectionContainer.section_name)
                    updateMealDishes(meal, action.payload.profile, action.payload.menu, action.payload.section)
                }
                else {
                    meal.barSectionContainers = meal.barSectionContainers.filter(bsc => bsc.section_name !== action.payload.barSectionContainer.section_name)
                    meal.barSectionContainers.push(action.payload.barSectionContainer)
                    updateMealDishes(meal, action.payload.profile, action.payload.menu, action.payload.section)
                }

            }
            else {
                meal.barSectionContainers = [ action.payload.barSectionContainer ]
            }
        }
    },
})
  
export const { toggleDish, clearMealDishes, setLevel, checkMenu, setContainer } = mealSlice.actions

export default mealSlice.reducer