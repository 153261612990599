import { ISection } from '@myfoodlifesas/db/lib/main/models/menu';
import { weekdayNames, monthNames } from '../resources/constants';
import MealModel from '../model/MealModel';
export function range(from: number, to: number, step = 1): number[] {
    const result: number[] = [];

    if (step === 0) {
        throw new Error('Step cannot be zero');
    }

    const direction = Math.sign(to - from);
    for (let i = from; direction * (i - to) <= 0; i += step * direction) {
        result.push(parseFloat(i.toFixed(10)));
    }

    return result;
}

declare global {
    interface Date {
        formated(): string;
    }
}

function formated(this: Date): string {
    return weekdayNames[this.getDay()] + ' ' + this.getDate() + ' ' + monthNames[this.getMonth()]
}

Date.prototype.formated = formated

export function nbDishesSelectedInSection(section: ISection, meal: MealModel): number {
    const nb = section.dishes.filter((dishInSection) => meal.dishes.map((dishInMeal) => dishInMeal.id).includes(dishInSection.id)).length
    if (section.bar && nb > 0) {
        return 1
    }
    return nb
}