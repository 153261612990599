import '../styles/main.sass'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export default function MenuNotFound() {

    const navigate = useNavigate()

    return (

        <div id='menu-not-found'>

            <span>Le menu du restaurant n&apos;a pas pu être chargé</span>

            <div id='exit-button' onClick={() => navigate(0)} >
                réessayer
            </div>
        
        </div>
        
    )
}