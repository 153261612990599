import '../styles/Section.sass'

import React from 'react'
import { useAppSelector } from '../store/hook'
import Container from './Container'
import Dish from './Dish'
import { nbDishesSelectedInSection } from '../utility/functions'
import { IMenu } from '@myfoodlifesas/db'
import { ISection } from '@myfoodlifesas/db/lib/main/models/menu'
import { IFeatures } from '@myfoodlifesas/db/lib/main/models/restaurant'

function Section({section, menu, features, context}: {section: ISection, menu: IMenu, features?: IFeatures, context: string }) {
    const meal = useAppSelector(state => state.meal)
    const nb = nbDishesSelectedInSection(section, meal)
    return (
        <div className='section'>
            <div className='section-name'>
                {section.name}
                { nb > 0 ? <div className='nb-dishes-selected'>{ section.bar ? 1 : nb }</div> : '' }
            </div>
            { (section.bar) &&
                <div className='bar-section-containers'>
                    { section.containers?.concat().sort((a,b) => a.weight - b.weight).map((container) => (
                        <Container key={container.name} container={container} section={section} />
                    ))}
                </div>
            }
            <div className={`dish-list ${features?.display_dish_images ? 'with-img' : ''}`} >
                {section.dishes.map((dish) => (
                    <Dish key={dish.id} dish={dish} section={section} menu={menu} features={features} context={context} />
                ))}
            </div>
        </div>
        )
}

export default Section