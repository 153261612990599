import React from 'react'
import '../styles/NotFoundPage.sass'

function NotFoundPage() {

    return (

      <div>
        <div className="info-site">
          <div className="fixed-top">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <nav className="navbar navbar-light">
                    <a className="navbar-brand me-0 me-sm-3" href="https://compose.myfoodlife.com">
                      <img className="logo-head" alt="Logo myfoodlife" src='./assets/myfoodlife_logo_white_bordered_14.svg'></img>
                    </a>
                    <a className="contact-us" href="mailto:contact@myfoodlife.app?subject=en%20savoir%20plus%20sur%20myfoodlife%20compose&body=Bonjour%20myfoodlife%2C%0D%0A%0D%0AJe%20souhaiterais%20en%20savoir%20plus%20sur%20myfoodlife%20compose.%0D%0APourrions-nous%20en%20discuter%20prochainement%20%3F%0D%0A%0D%0ACordialement%2C%0D%0A">
                      contactez-nous
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>


          <div className="block-tagline d-flex align-items-center position-relative">
            <div className="col-10 offset-1 col-md-8 offset-md-2">
              <h1 className="text-center mt-5 fw-bold">
                  <img className="img-fluid hero-logo" src="./assets/myfoodlife_compose_white_logo_1600-min.png"></img>
                  <span className="visually-hidden">myfoodlife</span>
              </h1>
              <div className="hero-baseline">
                <div>
                &nbsp;Composer&nbsp;son&nbsp;repas&nbsp;
                </div>
                <div>
                &nbsp;selon&nbsp;ses&nbsp;envies&nbsp;et&nbsp;ses&nbsp;besoins&nbsp;
                </div>
                <div>
                &nbsp;n&apos;a&nbsp;jamais&nbsp;été&nbsp;aussi&nbsp;simple.&nbsp;
                </div>
              </div>
            </div>
          </div>

          <div className="container-xl">
            <div className="row">
              <div className="col-xs-12 col-sm-10 offset-sm-1">
                <h2 className="text-center mt-4 mb-4">Réinventez l&apos;expérience&nbsp;convive<br/>avec&nbsp;un&nbsp;menu&nbsp;du&nbsp;jour <b>interactif</b>&nbsp;et&nbsp;<b>personnalisé</b>.</h2>
              </div>  
            </div>  
          </div>  

          <div className="container-xl bg-white my-3">
            <div className="row">
              <div className="background-image-6 col-12 offset-sm-2 col-sm-8 offset-sm-2 offset-md-0 col-md-4 d-flex home-block-content position-relative">
                <span className="fixed-position-keyword h1 text-white position-absolute w-100 text-center align-self-end text-uppercase">Innovant</span>
              </div>
              <div className="col-12 offset-sm-2 col-sm-8 offset-sm-2 offset-md-1 col-md-6 pt-3 pb-5 p-md-0 align-items-center d-flex">
                <div className="d-flex flex-column">
                  <h3 className="mb-4 h5 text-333">Sur-mesure. Et ça, c&apos;est nouveau.</h3>
                  <p>Votre restaurant met chaque jour toute son énergie pour proposer une offre de qualité, saine, variée et responsable. Mais comment bien s&apos;adresser à tous et en même temps à&nbsp;chacun&nbsp;?</p>
                  <p><b>myfoodlife compose</b> s&apos;intègre à vos apps. Son algorithme personnalise le menu du jour pour chaque convive selon son profil pour lui offrir une expérience inédite.</p>
                  <p>Bien manger tous les jours : casse-tête résolu.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container-xl bg-mfl-f8-stripe my-3">
            <div className="row flex-sm-row-reverse">
              <div className="d-none d-sm-block d-md-none col-2"></div>
              <div className="background-image-1 col-12 offset-sm-2 col-sm-8 offset-sm-2 offset-md-1 col-md-4 d-flex home-block-content position-relative">
                <span className="fixed-position-keyword h1 text-white position-absolute w-100 text-center align-self-end text-uppercase">Pertinent</span></div>
              <div className="d-none d-sm-block d-md-none col-2"></div>
              <div className="col-12 offset-sm-2 col-sm-8 offset-sm-2 offset-md-0 col-md-6 pt-3 pb-5 p-md-0 align-items-center d-flex">
                <div className="d-flex flex-column">
                  <h3 className="mb-4 h5 text-333">Tous différents. Tous concernés.</h3>
                  <p>Les convives attendent toujours plus, en particulier dans un contexte de restauration répétée&nbsp;: ils veulent bien manger, au sens du plaisir mais aussi de l&apos;équilibre.</p>
                  <p>Par son impact sur la santé et le bien-être, le restaurant a un rôle clé à jouer mais comment répondre de façon personnalisée aux envies et aux besoins de chaque convive&nbsp;?</p>
                  <p><b>myfoodlife compose</b> permet à chacun de trouver chaque jour la combinaison qui maximise son plaisir et son équilibre.</p>
                </div>
              </div>
            </div>
          </div>


          <div className="container-xl  bg-white my-3">
            <div className="row">
              <div className="background-image-5 col-12 offset-sm-2 col-sm-8 offset-sm-2 offset-md-0 col-md-4 d-flex home-block-content position-relative">
                <span className="fixed-position-keyword h1 text-white position-absolute w-100 text-center align-self-end text-uppercase">Différenciant</span>
              </div>
              <div className="col-12 offset-sm-2 col-sm-8 offset-sm-2 offset-md-1 col-md-6 pt-3 pb-5 p-md-0 align-items-center d-flex">
                <div className="d-flex flex-column">
                  <h3 className="mb-4 h5 text-333">Une raison de plus de manger dans votre restaurant.</h3>
                  <p>La personnalisation réinvente l&apos;expérience du restaurant et lui donne un nouveau souffle pour en faire l&apos;option préférée de vos convives.</p>
                  <p><b>myfoodlife compose</b> : une innovation clé-en-main au service de la satisfaction et de la fidélisation.
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="block-tagline-2 align-items-center position-relative mb-5">
            <div className="row">
                <div className="col-10 offset-1 col-md-8 offset-md-2">
                  <h1 className="text-center mt-4 mb-4 fw-bold">
                      <img className="img-fluid hero-logo" src="./assets/myfoodlife_compose_white_logo_1600-min.png"></img>
                      <span className="visually-hidden">myfoodlife</span>
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="block-exit mb-4 col-xs-10 offset-xs-1 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <p>est une <b>brique de service clé-en-main</b> qui s&apos;adresse&nbsp;:</p>
                  <ul>
                    <li>aux opérateurs de restauration</li>
                    <li>aux entreprises</li>
                    <li>aux propriétaires immobiliers</li>
                    <li>et à leurs conseils</li>
                  </ul>
                 <p>et constitue une solution innovante, pertinente et différenciante <b>au&nbsp;service de leurs grands enjeux&nbsp;:</b></p>
                  <ul>
                    <li>expérience convive et collaborateur</li>
                    <li>marque employeur, bien-être et QVT, attraction et fidélisation des talents</li>
                    <li>commercialisation des espaces</li>
                    <li>engagement et rapport sur les actions RSE</li>
                  </ul>
              </div>
              </div>

              <div className="row">
                <div className="col-xs-10 offset-xs-1 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="mt-3 text-center text-white">
                    <p>Pour une démonstration, une question, un partenariat :</p>
                    <a className="mb-4 contact-us" href="mailto:contact@myfoodlife.app?subject=en%20savoir%20plus%20sur%20myfoodlife%20compose&body=Bonjour%20myfoodlife%2C%0D%0A%0D%0AJe%20souhaiterais%20en%20savoir%20plus%20sur%20myfoodlife%20compose.%0D%0APourrions-nous%20en%20discuter%20prochainement%20%3F%0D%0A%0D%0ACordialement%2C%0D%0A">
                      contactez-nous aujourd&apos;hui
                    </a>
                  </div>
                </div>
              </div>

          </div>



        </div>

    </div>

    )
}

export default NotFoundPage