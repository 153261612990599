import { IDish, ISection } from "@myfoodlifesas/db/lib/main/models/menu"
import { mealTypes, mealLevels } from "../resources/constants"
import BarSectionContainerModel from "./BarSectionContainerModel"
import ProfileModel, { userDailyEnergyNeeds } from './ProfileModel'
import { IMenu } from "@myfoodlifesas/db"

export default interface MealModel {
    date: string
    dishes: IDish[]
    barSectionContainers: BarSectionContainerModel[]
    type: keyof typeof mealTypes
    level: keyof typeof mealLevels
}

export function energyFromSection(meal: MealModel, menu: IMenu, section: ISection, withDish?: IDish): number {
    let mealDishesIds = meal.dishes.map((dish) => dish.id)
    
    if (withDish) {
        if (!mealDishesIds.includes(withDish.id)) {
            mealDishesIds = mealDishesIds.concat([withDish.id])
        }
    }

    const dishesIncludedInSection = section.dishes.filter((dish) => mealDishesIds.includes(dish.id))
    let energy = 0

    if (!section.bar) {
        energy += dishesIncludedInSection.reduce((acc, dish) => acc + dish.energy, 0)
    }
    else {
        const barSectionContainer = meal.barSectionContainers.find((bsc) => bsc.section_name === section.name)
        if (barSectionContainer) {
            const container = section.containers?.find((c) => c.name === barSectionContainer.container_name)
            if (container) {
                const nonAdditiveDishesIncludedInSection = dishesIncludedInSection.filter((dish) => dish.non_additive)
                if (nonAdditiveDishesIncludedInSection.length > 0) {
                    energy += container.weight/100 * (nonAdditiveDishesIncludedInSection.reduce((acc, dish) => acc + dish.energy, 0)) / nonAdditiveDishesIncludedInSection.length
                }
                const additiveDishesIncludedInSection = dishesIncludedInSection.filter((dish) => !dish.non_additive)
                energy += additiveDishesIncludedInSection.reduce((acc, dish) => acc + dish.energy, 0)        
            }
        }
    }

    return Math.ceil(energy)
}

export function energy(meal: MealModel, menu: IMenu, withDish?: IDish): number {
    let energy = 0

    menu.sections.forEach((section) => {
        energy += energyFromSection(meal, menu, section, withDish)
    })
    
    return Math.ceil(energy)
}

export function mealMaxEnergy(meal: MealModel, profile: ProfileModel): number {
    return Math.trunc(userDailyEnergyNeeds(profile)
        * mealTypes['lunch'].ratio / (1 - (profile.skipBreakfast ? mealTypes['breakfast'].ratio : 0))
        * mealLevels[meal.level].coeff)
}