import React from 'react'

function ProfileHeader() {
  
    return (
        <div className='profile-header'>
            <img className='user-icon' alt='icône utilisateur' src='/assets/user_icon.svg' />
        </div>
        )
}


export default ProfileHeader