import '../styles/main.sass'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export default function RestaurantNotFound() {

    const navigate = useNavigate()

    return (

        <div id='restaurant-not-found'>

            <span>Le restaurant n&apos;a pas été trouvé</span>

            <div id='exit-button' onClick={() => navigate(0)} >
                réessayer
            </div>
        
        </div>
        
    )
}