import React from 'react'

import '../styles/Dish.sass'
import { useAppDispatch, useAppSelector } from '../store/hook'
import { toggleDish, notSelectable } from '../store/reducers/meal'
import { IFeatures } from '@myfoodlifesas/db/lib/main/models/restaurant'
import { IMenu } from '@myfoodlifesas/db'
import { IDish, ISection } from '@myfoodlifesas/db/lib/main/models/menu'

function Dish({ dish, section, menu, features, context}: { dish: IDish, section: ISection, menu: IMenu, features?: IFeatures, context: string }) {
    const meal = useAppSelector(state => state.meal)
    const profile = useAppSelector(state => state.profile)
    const dispatch = useAppDispatch()

    const isSelected = meal.dishes.map((d) => d.id).includes(dish.id)
    const isNotSelectable: boolean = !isSelected && notSelectable(dish, section, meal, profile, menu)

    let displayDishEnergy = true
    if (section.bar) {
        displayDishEnergy = false
    }
    
    if (features?.display_dish_images)
        return (
            <div
            className={`dish with-img ${isSelected ? 'is-selected' : ''} ${isNotSelectable ? 'not-selectable' : ''}`}
            onClick={ () => isNotSelectable || context!='compose' ? null : dispatch(toggleDish({dish: dish, section: section, meal: meal, profile: profile, menu: menu })) }
            >
            <div className='dish-cover'>
                <img src={dish.image_url || '/assets/empty_dish_image_url.png'} alt={dish.name} />
            </div>
            <div className={`dish-name`}>
                {dish.name} {dish.name}
            </div>
            {context !== 'compose' && (
                <div className={`dish-energy`}>
                    { displayDishEnergy ? dish.energy + ' kCal' : null}
                </div>
            )}
        </div>
            
        )
    return (
        <div
            className={`dish without-img ${isSelected ? 'is-selected' : ''} ${isNotSelectable ? 'not-selectable' : ''}`}
            onClick={ () => isNotSelectable || context!='compose' ? null : dispatch(toggleDish({dish: dish, section: section, meal: meal, profile: profile, menu: menu })) }
            >
            <>
            {context === 'compose' && (
                <div className='dish-check'>
                    { isSelected ? <img className='check-icon' src='/assets/check.svg'/> : '' }
                </div>
            )}
            </>
            <>
            {context === 'compose' && section.bar && !isSelected && !isNotSelectable && !dish.non_additive ? (
                <div className='topping'>
                    <img className='plus-sign-icon' src='/assets/plus_sign_icon.svg'/>
                </div>
            ) : null }
            </>
            <div className={`dish-name`}>
                {context !== 'compose' && section.bar ? '• ' : null} {dish.name}
            </div>
            {features?.display_dish_nutriscores === true && dish.nutriscore && (
                <img className={`dish-nutriscore`} src={`/assets/nutriscore/nutri_score_` + dish.nutriscore + '.svg'}/>
            )}
            {context !== 'compose' && (
                <div className={`dish-energy`}>
                    { displayDishEnergy ? dish.energy + ' kCal' : null}
                </div>
            )}
        </div>
    )
 }
 
 export default Dish